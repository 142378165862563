var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"id":"recurring-reservation-list"}},[_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQueryRecurringReservations),callback:function ($$v) {_vm.searchQueryRecurringReservations=$$v},expression:"searchQueryRecurringReservations"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.computedTableColumnsRecurringReservations,"items":_vm.dataListTableRecurringReservations,"item-key":"id","options":_vm.optionsRecurringReservations,"loading":_vm.loadingRecurringReservations,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page')}},on:{"update:options":function($event){_vm.optionsRecurringReservations=$event}},scopedSlots:_vm._u([{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(((item.start_time) + " - " + (item.end_time)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status_str)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"info"},on:{"click":function($event){return _vm.onShow(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t('tooltip.detail')))])]),(item.status === 'A')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"error"},on:{"click":function($event){return _vm.onCancel(item.id)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t('tooltip.cancel')))])]):_vm._e()]}}],null,true)})],1),(_vm.currentReservationId)?_c('cancellation-modal',{attrs:{"is-open":_vm.isOpenCancellationModal,"reasons":_vm.cancellationReasons,"reservation-id":null,"recurring-id":_vm.currentReservationId},on:{"openClose":function (val) {
      _vm.isOpenCancellationModal = val
      if (!val) { _vm.currentReservationId = null }
    },"refresh":_vm.fetchRecurringReservations}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }