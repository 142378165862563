<template>
  <div>
    <card-details
      class="mb-5"
      :field="dataParams.field_name"
      :date="dataParams.reservation_date"
      :time="dataParams.time_str"
      :sport="dataParams.sport_name"
      :origin="'TO DO'"
      :price="dataParams.reservation_price"
    />

    <div class="mb-5">
      <label class="font-weight-medium">Add-ons</label>
      <v-card
        color="#c5c5c5"
        class="d-flex align-center mt-2"
        :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
      >
        <v-img
          src="@/assets/images/pages/1.png"
          max-width="100"
          cover
        />
        <v-card-text class="d-flex flex-column py-0">
          <span class="text-body-1">{{ $t('reservations.video_recording') }}</span>
          <span class="text-sm">{{ dataParams.video_price ? formatCurrency(dataParams.video_price) : '$0.00' }}</span>
        </v-card-text>
      </v-card>
    </div>

    <participants
      v-if="false"
      class="mb-5"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { formatCurrency } from '@core/utils'

import CardDetails from '@/views/reservations/components/CardDetails.vue'
import Participants from '@/views/reservations/components/Participants.vue'

export default {
  components: {
    CardDetails,
    Participants,
  },
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const computedDataParams = computed(() => props.dataParams)

    return {
      computedDataParams,
      formatCurrency,
    }
  },
}
</script>

<style>

</style>
