<template>
  <v-card
    id="reservation-list"
    class="mb-8"
  >
    <v-card-title>
      {{ t('tooltip.filters') }}
    </v-card-title>

    <v-row
      class="px-2 ma-0 text-center"
    >
      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="fieldReservations"
          :placeholder="t('fields.field')"
          :items="fieldsOptions"
          item-text="name"
          item-value="id"
          outlined
          clearable
          dense
          hide-details
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="statusReservations"
          :items="statusOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          clearable
          :placeholder="t('select.status')"
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="statusPaymentReservations"
          :items="statusPaymentOptions"
          item-text="text"
          item-value="value"
          :placeholder="t('reservations.payment_status')"
          single-line
          outlined
          dense
          hide-details
          clearable
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-menu
          v-model="modalFromReservations"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFromReservations"
              :placeholder="t('transactions.from_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFromReservations"
            color="secondary"
            :max="dateToReservations ? dateToReservations : null"
            @input="modalFromReservations = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-menu
          v-model="modalToReservations"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateToReservations"
              :placeholder="t('transactions.to_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateToReservations"
            color="secondary"
            :min="dateFromReservations ? dateFromReservations : null"
            @input="modalToReservations = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="start"
      class="px-2 ma-0"
    >
      <v-col
        cols="12"
        sm="12"
        md="3"
      >
        <v-text-field
          v-model="searchQueryReservations"
          :placeholder="t('search')"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider />

    <v-data-table
      :headers="computedTableColumnsReservations"
      :items="dataListTableReservations"
      item-key="reservation_id"
      :loading="loadingReservations"
      :options.sync="optionsReservations"
      :loading-text="t('table.loading_text')"
      :no-data-text="t('table.no_data')"
      :header-props="{sortByText: t('table.sort_by')}"
      :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
      :items-per-page="-1"
      :search.sync="searchQueryReservations"
      :sort-desc.sync="descSortReservations"
      :server-items-length="totalDataListTableReservations"
    >
      <template #[`item.date_str`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="font-weight-semibold text-truncate">{{ item.date_str }}</span>
            <span
              class="text-xs"
            >
              {{ t('videos.video') }} ID: {{ item.video_id || '' }}
            </span>
          </div>
        </div>
      </template>

      <template #[`item.payment_status_str`]="{item}">
        <v-chip
          small
          :color="resolveStatusPaymentVariant(item.payment_status)"
          :class="`${resolveStatusPaymentVariant(item.payment_status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.payment_status_str }}
        </v-chip>
      </template>

      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="resolveStatusVariant(item.status)"
          :class="`${resolveStatusVariant(item.status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.status_str }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{item}">
        <v-tooltip
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="info"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onShow(item)"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.detail') }}</span>
        </v-tooltip>

        <!-- <v-tooltip
          v-if="item.status === 'A'"
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="error"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onCancel(item.id)"
            >
              {{ icons.mdiCancel }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.cancel') }}</span>
        </v-tooltip> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiCancel,
  mdiCalendarSync,
  mdiEyeOutline,
} from '@mdi/js'
import { computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'

import { cancelRecurringReservation } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useReservationList from './useReservationList'

export default {
  setup() {
    const { t, tInContext } = useUtils()
    const { router, route } = useRouter()
    const vm = getCurrentInstance().proxy

    const {
      dataListTableReservations,
      computedTableColumnsReservations,
      totalDataListTableReservations,
      searchQueryReservations,
      statusReservations,
      statusPaymentReservations,
      fieldReservations,
      modalFromReservations,
      modalToReservations,
      dateFromReservations,
      dateToReservations,
      loadingReservations,
      descSortReservations,
      optionsReservations,

      filters,
      maxDate,
      fieldsOptions,
      configFacility,

      fetchFields,
      fetchReservations,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      hasPermission,
      setFilters,
      setOptions,
      getFilterBySubmodule,
    } = useReservationList()

    const statusOptions = computed(() => [
      { text: t('status.confirmed'), value: 'F' },
      { text: t('status.cancelled'), value: 'C' },
    ])

    const statusPaymentOptions = computed(() => [
      { text: t('status.pending'), value: 'N' },
      { text: t('status.hold'), value: 'H' },
      { text: t('status.paid'), value: 'P' },
    ])

    const onShow = item => {
      router.push({
        name: 'views-reservations-form',
        params: {
          id: item.reservation_id,
          dataParams: item,
          option: 2,
          pageParams: optionsReservations.value,
          filterParams: filters.value,
        },
      })
    }

    const onCancel = async id => {
      const customTitle = `${tInContext('questions.are_you_sure', vm)}`
      const customConfirm = `${tInContext('reservations.delete_all_pending', vm)}`
      Swal.fire({
        title: customTitle,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: customConfirm,
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await cancelRecurringReservation(id)
          if (response.ok) {
            success(response.message)
            fetchReservations()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const reservationFilters = getFilterBySubmodule('views-recurring-reservation-list', 'reservations')

      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      await fetchFields(configFacility.value)
      await fetchReservations()

      if (reservationFilters.search) searchQueryReservations.value = reservationFilters.search
      if (reservationFilters.field) fieldReservations.value = reservationFilters.field
      if (reservationFilters.dateFrom) dateFromReservations.value = reservationFilters.dateFrom
      if (reservationFilters.dateTo) dateToReservations.value = reservationFilters.dateTo
      if (reservationFilters.status) statusReservations.value = reservationFilters.status
      if (reservationFilters.statusPayment) statusPaymentReservations.value = reservationFilters.statusPayment
      if (reservationFilters.option) optionsReservations.value = reservationFilters.option
    })

    return {
      statusOptions,
      statusPaymentOptions,

      dataListTableReservations,
      computedTableColumnsReservations,
      totalDataListTableReservations,
      searchQueryReservations,
      statusReservations,
      statusPaymentReservations,
      fieldReservations,
      modalFromReservations,
      modalToReservations,
      dateFromReservations,
      dateToReservations,
      loadingReservations,
      descSortReservations,
      optionsReservations,

      filters,
      maxDate,
      fieldsOptions,

      onShow,
      onCancel,
      avatarText,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      fetchReservations,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiCancel,
        mdiPlus,
        mdiEyeOutline,
        mdiCalendarSync,
      },
    }
  },
}
</script>
