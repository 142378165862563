<template>
  <div class="ma-5">
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">Leyends</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <v-checkbox
      v-for="item in computedLeyends"
      :key="item.text"
      v-model="item.selected"
      :color="item.color"
      :label="item.text"
      class="mt-2"
      hide-details
      readonly
    >
    </v-checkbox>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiCheckboxBlank, mdiLabelMultiple, mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { getRGBColor, getVuetify } from '@core/utils'

export default {
  setup() {
    const { t } = useUtils()
    const $vuetify = getVuetify()
    const labels = ref([
      {
        color: getRGBColor('group', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('groups.group'),
        selected: true,
      },
      {
        color: getRGBColor('pickUp', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.pick_up'),
        selected: true,
      },
      {
        color: getRGBColor('regular', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.regular'),
        selected: true,
      },
      {
        color: getRGBColor('academy', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.academies'),
        selected: true,
      },
      {
        color: getRGBColor('birthday', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.birthday'),
        selected: true,
      },
      {
        color: getRGBColor('tournament', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.tournament'),
        selected: true,
      },
      {
        color: getRGBColor('other', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.other'),
        selected: true,
      },
      {
        color: getRGBColor('blocked', $vuetify.theme),
        size: '18',
        icon: mdiCheckboxBlank,
        text: t('reservations.blocked'),
        selected: true,
      },
    ])
    const computedLeyends = computed(() => labels.value)

    return {
      computedLeyends,

      getRGBColor,

      t,

      icons: {
        mdiLabelMultiple,
        mdiCheckboxBlank,
        mdiClose,
      },
    }
  },
}

</script>
