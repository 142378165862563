<template>
  <v-card color="#c5c5c5">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('fields.field') }}</span>
            <span class="text-body-1">{{ field }}</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('videos.date') }}</span>
            <span class="text-body-1">{{ date ? dateFormat(date) : '' }}</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('videos.time') }}</span>
            <span class="text-body-1">{{ time }}</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('sports.sport') }}</span>
            <span class="text-body-1">{{ sport }}</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('reservation_detail.origin') }}</span>
            <span class="text-body-1">{{ origin }}</span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex"
        >
          <div class="d-flex flex-column">
            <span>{{ $t('reservation_detail.price_per_participant') }}</span>
            <span class="text-body-1">{{ price ? formatCurrency(price) : '$0.00' }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { formatCurrency } from '@core/utils'
import moment from 'moment'

export default {
  name: 'CardDetails',
  props: {
    field: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    sport: {
      type: String,
      default: '',
    },
    origin: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { t } = useUtils()

    const dateFormat = date => moment(date).format('ddd, MMM DD, YYYY')

    return {
      formatCurrency,
      dateFormat,
      t,
    }
  },
}
</script>
