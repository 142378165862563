<template>
  <div>
    <label class="font-weight-medium mb-3">{{ $t('reservations.participants') }}</label>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-btn-toggle
          v-model="qtyToggle"
          color="primary"
          dense
          group
          class="mt-2"
          @change="onChangeQty"
        >
          <v-btn
            :value="1"
            outlined
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            1
          </v-btn>

          <v-btn
            :value="computedPlayersQtyMinus"
            outlined
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQtyMinus }}
          </v-btn>

          <v-btn
            :value="computedPlayersQty"
            outlined
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQty }}
          </v-btn>

          <v-btn
            :value="computedPlayersQtyPlus"
            outlined
            class="btn-qty-border text-body-1 pa-0 ma-0 mr-3"
          >
            {{ computedPlayersQtyPlus }}
          </v-btn>

          <v-text-field
            v-model="qtyToggleCustom"
            class="text-body-1 d-flex align-center"
            placeholder="#"
            dense
            hide-details
            outlined
            :style="'max-width: 52px !important; min-width: 52px !important;'"
            @keypress="onlyNumbers"
            @change="e => {
              qtyToggle = null
              onChangeQty(e, true)
            }"
            @click.enter="e => {
              qtyToggle = null
              onChangeQty(e, true)
            }"
          ></v-text-field>
        </v-btn-toggle>
      </v-col>

      <v-col
        v-if="removeQtyAlert"
        cols="12"
      >
        <v-alert
          class="mb-0"
          type="error"
        >
          <template #prepend>
            <v-icon class="me-2">
              {{ icons.mdiAlert }}
            </v-icon>
          </template>
          Invalid number of participants
        </v-alert>
      </v-col>

      <v-col
        v-if="organizerAlert && isValidateForm"
        cols="12"
      >
        <v-alert
          class="mb-0"
          type="error"
        >
          <template #prepend>
            <v-icon class="me-2">
              {{ icons.mdiAlert }}
            </v-icon>
          </template>
          Organizer is required
        </v-alert>
      </v-col>
    </v-row>

    <participant-input
      v-for="(participant, index) in computedParticipants"
      :key="index"
      :place-index="index"
      :facility="facility"
      :data-params="participant"
      :label="index === 0 ? $t('reservations.organizer') : `${$t('reservations.participant')} (${$t('reservations.optional')})`"
      :selected-participant="selectedParticipant"
      :onsite-payment-methods-options="onsitePaymentMethodsOptions"
      :disabled-price="paymentType === 'P'"
      @updateNextEnabled="onUpdateNextEnabled"
    />

    <div class="d-flex my-5">
      <v-btn
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
        @click="onAddParticipant"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Add participant
      </v-btn>
      <v-btn
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
      >
        Mark all as Paid
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'
import { getContactFacilitySearch, getOnSitePaymentMethodsActiveByFacility } from '@api'
import {
  mdiPlus,
  mdiAlert,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
  emailValidator,
} from '@core/utils/validation'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import ParticipantInput from './ParticipantInput.vue'

export default {
  components: {
    ParticipantInput,
  },
  model: {
    prop: 'participants',
    event: 'update:participants',
  },
  props: {
    participants: {
      type: Array,
      default: () => [{
        id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: 0,
        paid: 'N',
        onsite_payment_method_id: null,
        empty: true,
        enabled: true,
        is_organizer: true,
      }],
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    playersQty: {
      type: Number,
      default: 1,
      required: true,
    },
    perParticipantPrice: {
      type: Number,
      default: 0,
      required: true,
    },
    isValidateForm: {
      type: Boolean,
      default: false,
      required: true,
    },
    paymentType: {
      type: String,
      default: 'F',
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { tInContext } = useUtils()
    const computedFacility = computed(() => props.facility)
    const computedPerParticipantPrice = computed(() => props.perParticipantPrice)
    const computedParticipants = computed({
      get: () => props.participants,
      set: val => emit('update:participants', val),
    })
    const selectedParticipant = computed(() => computedParticipants.value.filter(e => e.id).map(e => e.id))
    const computedPlayersQty = computed(() => props.playersQty)
    const computedPlayersQtyPlus = computed(() => props.playersQty + 2)
    const computedPlayersQtyMinus = computed(() => props.playersQty - 2)
    const empties = computed(() => computedParticipants.value.filter(e => e.empty).length)

    const participantsSearched = ref([])
    const qtyToggle = ref(props.playersQty)
    const qtyToggleCustom = ref(null)
    const contactSelectedId = ref(null)
    const searchQuery = ref(null)
    const searchLoading = ref(false)
    const timerSearch = ref(null)
    const onsitePaymentMethodsOptions = ref([])
    const removeQtyAlert = ref(false)
    const organizerAlert = computed(() => computedParticipants.value.every(e => e.empty))

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(props.facility || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [{
            id: 0,
            first_name: searchQuery.value,
            last_name: searchQuery.value,
            fullname: searchQuery.value,
            phone_number: searchQuery.value,
            email: searchQuery.value,
          }]

          participantsSearched.value = newContact.concat(response.data)
        } else {
          participantsSearched.value = []
        }
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 1000)
    }

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => { searchQuery.value = firSearch }, 50)
      }
      await getDataBySearch()
    })

    const addContact = index => {
      emit('addContact', searchQuery.value, index)
    }

    const onAddParticipant = () => {
      computedParticipants.value.push({
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: props.perParticipantPrice || 0,
        paid: 'N',
        onsite_payment_method_id: null,
        empty: true,
        enabled: empties.value === 0,
        is_organizer: false,
      })
      qtyToggleCustom.value = null
    }

    const onAddContact = (item, index) => {
      computedParticipants.value.at(index).id = item.id
      computedParticipants.value.at(index).app_user_id = item.id
      computedParticipants.value.at(index).price = null
      computedParticipants.value.at(index).paid = null
    }

    const onSelectContact = (val, index) => {
      if (val) {
        const itemDelected = participantsSearched.value.find(e => e.id === val)
        onAddContact(itemDelected, index)
      } else if (val === 0) {
        contactSelectedId.value = null

        participantsSearched.value = []

        // autoContact.value.reset()
        document.getElementById(`autoContact-${val}`).reset()
        addContact()
      } else {
        contactSelectedId.value = null
        participantsSearched.value = []
      }
    }

    const onChangeQty = (val, custom = false) => {
      if (!custom) qtyToggleCustom.value = null
      if (val && !Number.isNaN(val) && val > 0) {
        const qty = parseInt(val, 10)
        emit('selectPlayersQty', qty)
        if (qty > computedParticipants.value.length) {
          const newElements = qty - computedParticipants.value.length
          for (let i = 0; i < newElements; i += 1) {
            computedParticipants.value.push({
              id: null,
              contact_id: null,
              user_app_id: null,
              fullname: null,
              phone_number: null,
              email: null,
              final_amount: props.perParticipantPrice || 0,
              paid: 'N',
              onsite_payment_method_id: null,
              empty: true,
              enabled: empties.value === 0,
              is_organizer: false,
            })
          }
        }

        if (qty < computedParticipants.value.length) {
          const remove = computedParticipants.value.length - qty
          if (remove > empties.value) {
            removeQtyAlert.value = true
          } else {
            for (let i = 0; i < remove; i += 1) {
              computedParticipants.value.pop()
            }
            removeQtyAlert.value = false
          }
        }
      }
    }

    const onUpdateNextEnabled = inx => {
      if (inx <= computedParticipants.value.length - 1) computedParticipants.value.at(inx).enabled = true
    }

    watch([computedPerParticipantPrice], () => {
      console.log('🚀 ~ watch ~ computedPerParticipantPrice:', computedPerParticipantPrice.value)
      computedParticipants.value.forEach(e => {
        e.final_amount = computedPerParticipantPrice.value
      })
    })

    watch([computedPlayersQty], () => {
      qtyToggleCustom.value = null
      for (let i = 0; i < computedPlayersQty.value - 1; i += 1) {
        computedParticipants.value.push({
          id: null,
          contact_id: null,
          user_app_id: null,
          fullname: null,
          phone_number: null,
          email: null,
          final_amount: props.perParticipantPrice || 0,
          paid: 'N',
          onsite_payment_method_id: null,
          empty: true,
          enabled: empties.value === 0,
          is_organizer: i === 0,
        })
      }
    })

    watch([computedFacility], async () => {
      if (computedFacility.value) {
        onsitePaymentMethodsOptions.value = []
        const onsitePaymentMethods = await getOnSitePaymentMethodsActiveByFacility(props.facility)
        if (onsitePaymentMethods.ok) {
          onsitePaymentMethodsOptions.value = onsitePaymentMethods.data
          onsitePaymentMethodsOptions.value.unshift({
            id: '*',
            name: tInContext('tooltip.unpaid', vm),
          })
        }
      }
    }, { immediate: true })

    return {
      computedPerParticipantPrice,
      computedParticipants,
      selectedParticipant,
      participantsSearched,
      computedPlayersQty,
      computedPlayersQtyPlus,
      computedPlayersQtyMinus,
      qtyToggle,
      qtyToggleCustom,
      contactSelectedId,
      searchQuery,
      searchLoading,
      onsitePaymentMethodsOptions,

      removeQtyAlert,
      organizerAlert,

      onAddParticipant,
      onSelectContact,
      onAddContact,
      addContact,
      avatarText,
      onChangeQty,
      onUpdateNextEnabled,

      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      emailValidator,
      onlyNumberDot,
      onlyNumbers,

      icons: {
        mdiPlus,
        mdiAlert,
      },
    }
  },
}
</script>
<style>
.v-list-item {
  height: 100% !important;
}

.btn-qty-border {
  border: 1px solid #00000024 !important;
  border-radius: 5px !important;
  min-width: 52px !important;
  max-width: 52px !important;
  max-height: 40px !important;
  min-height: 40px !important;
}
</style>
