<template>
  <div>
    <v-icon
      v-if="showShare"
      :color="shareIconColor"
      size="20"
      class="mr-2"
      @click.prevent="onShare"
    >
      {{ icons.mdiShareVariant }}
    </v-icon>

    <v-icon
      v-if="showCopy"
      :color="copyIconColor"
      size="20"
      @click.prevent="onShare"
    >
      {{ icons.mdiShareVariant }}
    </v-icon>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-newline */
import { computed, getCurrentInstance } from '@vue/composition-api'
import { mdiShareVariant, mdiContentCopy } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { success, error } from '@core/utils/toasted'
import useShare from '@core/utils/useShare'
import moment from 'moment'
import { copyText } from 'vue3-clipboard'
import { useClipboard } from '@vueuse/core'
import { isMobile } from '@core/utils'

export default {
  props: {
    isPrevent: {
      type: Boolean,
      default: false,
      required: true,
    },
    link: {
      type: String,
      default: '',
      required: true,
    },
    date: {
      type: String,
      require: true,
      default: '',
    },
    startTime: {
      type: String,
      require: true,
      default: '',
    },
    location: {
      type: String,
      require: true,
      default: '',
    },
    sport: {
      type: String,
      require: true,
      default: '',
    },
    showShare: {
      type: Boolean,
      default: true,
      required: false,
    },
    showCopy: {
      type: Boolean,
      default: true,
      required: false,
    },
    shareIconColor: {
      type: String,
      default: 'primary',
      required: false,
    },
    copyIconColor: {
      type: String,
      default: 'primary',
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { shareURL, isSupportedShare } = useShare()
    const { copy, isSupported } = useClipboard()
    const vm = getCurrentInstance().proxy

    const computedDate = computed(() => props.date)
    const computedStartTime = computed(() => props.startTime)
    const computedLocation = computed(() => props.location)
    const computedSport = computed(() => props.sport)
    const computedLink = computed(() => props.link)
    const computedIsPrevent = computed({
      set: val => emit('update:is-prevent', val),
      get: () => props.isPrevent,
    })

    const formatVideoName = item => moment(`${item}`, 'YYYY-MM-DD hh:mm').format('dddd HH:mm, MMM DD')

    const onShare = () => {
      try {
        computedIsPrevent.value = true
        if (isSupportedShare.value && isMobile()) {
          shareURL(
            'Fanaty',
            `Date: ${computedDate.value}\nTime: ${computedStartTime.value}\nLocation: ${computedLocation.value}\n\nManage booking:\n${computedLink.value}\n\nVideo recording available via the link.`,
            computedLink.value,
          )
        } else {
          emit('openUpdateShare', true)
        }
      } catch (e) {
        console.error(e)
        emit('openUpdateShare', true)
      }
    }

    const onCopy = () => {
      computedIsPrevent.value = true
      copyText(computedLink.value, undefined, e => {
        if (e) {
          console.error('error')
          error(vm.$t('tooltip.reservation_link_error'))
        } else {
          success(vm.$t('reservations.reservation_link_copied'))
        }
      })
    }

    const copyActiveCode = () => {
      if (!isSupported) error(vm.$t('tooltip.reservation_link_error'))
      else {
        computedIsPrevent.value = true
        copy(computedLink.value).then(() => {
          setTimeout(() => {
            success(vm.$t('reservations.reservation_link_copied'))
          }, 500)
        })
      }
    }

    return {
      computedIsPrevent,

      computedDate,
      computedStartTime,
      computedLocation,
      computedSport,
      computedLink,

      formatVideoName,
      onShare,
      onCopy,
      copyActiveCode,

      t,

      icons: {
        mdiShareVariant,
        mdiContentCopy,
      },
    }
  },
}
</script>
