<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card class="pa-5 pa-sm-8">
      <v-btn
        class="v-dialog-close-btn"
        variant="text"
        size="small"
        icon
        @click="isOpen = false"
      >
        <v-icon
          size="22"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>

      <v-card-text class="text-center">
        <v-card-title class="text-h5 mb-1 justify-center">
          {{ $t('reservation_detail.cancel_booking') }}
        </v-card-title>

        <v-card-subtitle>
          {{ $t('reservation_detail.cancel_booking_info') }}
        </v-card-subtitle>
      </v-card-text>

      <v-card-text v-if="reasons.length">
        <v-list
          mandatory
          variant="outlined"
          class="card-list auth-method-card"
          :class="$vuetify.breakpoint.xs ? 'responsive-card' : ''"
        >
          <custom-radios
            v-if="reasons"
            :radio-content="reasons"
            :selected-radio.sync="selectedReason"
            :slide-display="false"
            :grid-column="{ cols: '12', sm: '12' }"
          />
          <!-- @update:selectedRadio="onSelectReason" -->
        </v-list>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pb-0">
        <v-btn
          v-if="reservationId"
          color="secondary"
          @click="onContinue('booking')"
        >
          {{ t('reservation_detail.cancel_booking') }}
        </v-btn>
        <v-btn
          v-if="recurringId"
          color="secondary"
          @click="onContinue('recurring')"
        >
          {{ t('reservation_detail.delete_all_the_following') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-newline */
import { ref, computed } from '@vue/composition-api'
import { cancelReservation, cancelRecurringReservation } from '@api'
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { success, error, info } from '@core/utils/toasted'

import CustomRadios from '@core/components/custom-radios/CustomRadios.vue'

export default {
  name: 'CancellationModal',
  components: {
    CustomRadios,
  },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    reasons: {
      type: Array,
      default: () => [],
      required: true,
    },
    reservationId: {
      type: Number,
      default: 0,
    },
    recurringId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const selectedReason = ref('')
    const computedReservationId = computed(() => props.reservationId)
    const computedRecurringId = computed(() => props.recurringId)

    const onContinue = async (service = 'booking') => {
      if (selectedReason.value) {
        let resp = null
        const body = {
          cancellation_reason_id: selectedReason.value,
        }
        if (service === 'recurring') {
          resp = await cancelRecurringReservation(computedRecurringId.value, body)
        } else {
          resp = await cancelReservation(computedReservationId.value, body)
        }

        if (resp.ok) {
          success(resp.message)
          emit('openClose', false)
          emit('refresh')
        } else {
          error(resp.message.text)
        }
      } else {
        info('Select a reason!')
      }
    }

    return {
      selectedReason,

      // onSelectReason,
      onContinue,

      t,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.v-dialog {
  // dialog custom close btn
  .v-dialog-close-btn {
    position: absolute;
    z-index: 1;
    color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity)) !important;
    inset-block-start: 0.9375rem;
    inset-inline-end: 0.9375rem;

    .v-btn__overlay {
      display: none;
    }
  }
}
</style>
