<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center justify-space-between mb-0">
      <div class="d-flex flex-column flex-sm-row justify-sm-space-between align-start align-sm-center w-100 ">
        <div class="d-flex align-center mb-2 mb-sm-0">
          <span class="font-weight-semibold text-base text--primary">{{ t('Booking') }} #{{ dataParams.reservation_id }}</span>
          <v-chip
            class="ml-2"
            small
            color="warning"
          >
            {{ dataParams.status_str }}
          </v-chip>
        </div>

        <div>
          <v-btn
            v-if="dataParams.recurring_id"
            class="mr-2"
            outlined
            :small="$vuetify.breakpoint.smAndDown"
            @click="$emit('recurring')"
          >
            <v-icon size="22">
              {{ icons.mdiAutorenew }}
            </v-icon>
            Recurring #{{ dataParams.recurring_id }}
          </v-btn>

          <v-btn
            class="mr-2 btn-share"
            outlined
            :small="$vuetify.breakpoint.smAndDown"
            @click="() => {
              $emit('share', dataParams)
              $emit('openClose', false)
            }"
          >
            <v-icon size="22">
              {{ icons.mdiShareVariant }}
            </v-icon>
          </v-btn>
        </div>
      </div>

      <v-btn
        icon
        small
        @click="$emit('openClose', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <div
      id="video-view"
      class="ma-4"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="currentTab"
            color="secondary"
            :show-arrows="true"
            style="box-shadow: none !important;"
          >
            <v-tab
              v-for="tab in computedTabs"
              :key="tab.title"
            >
              <v-icon
                v-if="tab.icon"
                size="20"
                class="me-2"
              >
                {{ tab.icon }}
              </v-icon>
              <span class="font-weight-bold">{{ $t(tab.title) }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            id="video-tabs-content"
            v-model="tab"
            class="mt-5 overflow-visible"
          >
            <v-tab-item
              v-for="tab in computedTabs"
              :key="tab.component"
            >
              <component
                :is="tab.component"
                v-bind="tab.props"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <div class="d-flex mb-5">
        <v-btn
          color="secondary"
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="isOpenCancellationModal = true"
        >
          Cancel Booking
        </v-btn>
        <v-btn
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="() => {
            $emit('share', dataParams)
            $emit('openClose', false)
          }"
        >
          Share
        </v-btn>

        <v-spacer />

        <v-btn
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="$emit('openClose', false)"
        >
          Close
        </v-btn>
      </div>
      <!-- <v-divider class="ma-0" /> -->
    </div>

    <cancellation-modal
      :is-open="isOpenCancellationModal"
      :reasons="reasons"
      :reservation-id="computedData.reservation_id"
      :recurring-id="computedData.recurring_id"
      @openClose="(val) => {
        isOpenCancellationModal = val
      }"
      @refresh="() => {
        $emit('openClose', false)
        $emit('refreshEvents')
      }"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  mdiClose,
  mdiShareVariant,
  mdiAutorenew,

  // mdiPlaylistCheck,
  // mdiLockClock,
} from '@mdi/js'
import usePermissions from '@core/utils/usePermissions'

import CustomRadios from '@core/components/custom-radios/CustomRadios.vue'
import ReservationDetails from './ReservationDetails.vue'
import ReservationChat from './ReservationChat.vue'
import ReservationNotes from './ReservationNotes.vue'
import ReservationHistory from './ReservationHistory.vue'
import CancellationModal from '../components/CancellationModal.vue'

// import RegularBookingForm from './RegularBookingForm.vue'
// import ListForSaleForm from './ListForSaleForm.vue'
// import BlockTimeForm from './BlockTimeForm.vue'

export default {
  components: {
    CustomRadios,
    ReservationDetails,
    ReservationChat,
    ReservationNotes,
    ReservationHistory,
    CancellationModal,
  },
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
    reasons: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()

    const computedData = computed(() => props.dataParams)
    const userData = ref({})
    const tab = ref(0)
    const currentTab = ref(0)
    const isOpenCancellationModal = ref(false)
    const cancellationReasons = ref([])
    const selectedReason = ref('')

    const tabs = ref([
      {
        icon: null,
        title: 'reservation_detail.edit_details',
        show: true,
        component: 'reservation-details',
        props: { dataParams: props.dataParams },
      },
      {
        icon: null,
        title: 'reservation_detail.chat',
        show: false,
        component: 'reservation-chat',
        props: { dataParams: props.dataParams },
      },
      {
        icon: null,
        title: 'reservation_detail.notes',
        show: false,
        component: 'reservation-notes',
        props: { dataParams: props.dataParams },
      },
      {
        icon: null,
        title: 'reservation_detail.history',
        show: false,
        component: 'reservation-history',
        props: { dataParams: props.dataParams },
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    return {
      userData,
      computedData,
      tab,
      currentTab,
      tabs,
      computedTabs,
      cancellationReasons,
      isOpenCancellationModal,
      selectedReason,

      hasPermission,
      t,

      icons: {
        mdiClose,
        mdiShareVariant,
        mdiAutorenew,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.v-btn--active::before {
  opacity: 0 !important;
}

.btn-share {
  min-width: 38px !important;
  padding: 0 !important;
}

@media (max-width: 599px) {
  .btn-share {
    min-width: 30px !important;
  }
}
</style>
