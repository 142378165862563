<template>
  <div
    id="reservation-new-form"
    class="h-full"
  >
    <div class="drawer-header d-flex align-center mb-0">
      <span class="font-weight-semibold text-base text--primary">{{ t('reservations.new_reservation') }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('update:is-open', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <v-row>
      <v-col
        cols="12"
      >
        <v-tabs
          v-model="currentTab"
          color="secondary"
          :show-arrows="true"
          style="box-shadow: none !important;"
        >
          <v-tab
            v-for="tab in computedTabs"
            :key="tab.title"
          >
            <v-icon
              size="20"
              class="me-2"
            >
              {{ tab.icon }}
            </v-icon>
            <span class="font-weight-bold">{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="ps-calendar-event-handler"
        ></perfect-scrollbar>
        <v-tabs-items
          id="reservation-tabs-content"
          v-model="currentTab"
          class="mt-5 px-2 overflow-visible"
        >
          <v-tab-item :key="0">
            <reservation-new-form
              :is-open.sync="isOpen"
              :editable.sync="editable"
              :data-params="dataParams"
              :types="types"
              :date="date"
              :group="group"
              :groups="groups"
              :facility="facility"
              :facilities="facilities"
              :fields="fields"
              :players-groups="playersGroups"
              :onsite-payment-methods="onsitePaymentMethods"
              :show-allow-video="showAllowVideo"
              :open-time="openTime"
              :close-time="closeTime"
              @openClose="$emit('openClose', $event)"
              @refreshEvents="$emit('refreshEvents')"
              @initData="$emit('initData')"
            ></reservation-new-form>
          </v-tab-item>

          <v-tab-item
            v-if="hasPermission(142)"
            :key="1"
          >
            <blocked-times-form
              :is-open="isOpen"
              :data-params="dataParams"
              :option="1"
              :from-video="false"
              :date="date"
              @refresh="$emit('refreshEvents')"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed, watch } from '@vue/composition-api'
import {
  mdiCalendarCheckOutline,
  mdiLockClock,
  mdiClockTimeFourOutline,
  mdiTimerOutline,
  mdiCalendarBlankOutline,
  mdiCalendar,
  mdiMenuDown,
  mdiClose,
  mdiPencilOutline,
  mdiMagnify,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiAlert,
  mdiPlaylistCheck,
} from '@mdi/js'
import usePermissions from '@core/utils/usePermissions'
import { useUtils } from '@core/libs/i18n'

// import RegularBookingForm from './RegularBookingForm.vue'
// import ListForSaleForm from './ListForSaleForm.vue'
// import BlockTimeForm from './BlockTimeForm.vue'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import ReservationNewForm from '@/views/reservations/form/ReservationNewForm.vue'
import BlockedTimesForm from '@/views/blocked-times/BlockedTimesForm.vue'

export default {
  components: {
    // RegularBookingForm,
    // ListForSaleForm,
    // BlockTimeForm,

    PerfectScrollbar,
    ReservationNewForm,
    BlockedTimesForm,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
      required: true,
    },
    types: {
      type: Array,
      default: () => [],
      required: true,
    },
    openTime: {
      type: String,
      default: '00:00',
      required: true,
    },
    closeTime: {
      type: String,
      default: '23:29',
      required: true,
    },
    date: {
      type: String,
      default: '',
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => {},
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
      required: true,
    },
    group: {
      type: Number,
      default: 0,
      required: true,
    },
    facilities: {
      type: Array,
      default: () => [],
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    playersGroups: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
    showAllowVideo: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  setup(props) {
    const { hasPermission } = usePermissions()
    const { t } = useUtils()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    const isOpenComputed = computed(() => props.isOpen)
    const userData = ref({})
    const tab = ref(0)
    const currentTab = ref(0)

    const tabs = ref([
      {
        icon: mdiCalendarCheckOutline,
        title: 'Regular Booking',
        show: true,
        component: 'reservation-new-form',
      },
      {
        icon: mdiLockClock, title: 'Block Times', show: hasPermission(142), component: 'block-time-form',
      },

      // {
      //   icon: mdiPlaylistCheck, title: 'List For Sale', show: true, component: 'list-for-sale-form',
      // },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    watch([isOpenComputed], () => {
      if (!isOpenComputed.value) currentTab.value = 0
    })

    return {
      perfectScrollbarSettings,
      userData,
      tab,
      currentTab,
      tabs,
      computedTabs,

      hasPermission,
      t,

      icons: {
        mdiClockTimeFourOutline,
        mdiTimerOutline,
        mdiCalendarBlankOutline,
        mdiCalendar,
        mdiMenuDown,
        mdiClose,
        mdiPencilOutline,
        mdiMagnify,
        mdiEyeOutline,
        mdiInformationOutline,
        mdiAlert,
        mdiPlaylistCheck,
        mdiLockClock,
        mdiCalendarCheckOutline,
      },
    }
  },
}
</script>
<style lang="scss">
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
