<template>
  <div>
    <v-card>
      <v-card-title>
        Chat
      </v-card-title>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ReservationChat',
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
  },
  setup() {

  },
}
</script>
