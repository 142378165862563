<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
      >
        {{ notificationsCount ? icons.mdiBellBadgeOutline : icons.mdiBellOutline }}
      </v-icon>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">{{ t('reservations.incomplete_reservations') }}</span>
              <v-spacer v-if="notificationsCount" />
              <v-chip
                v-if="notificationsCount"
                class="v-chip-light-bg font-weight-semibold"
                small
              >
                {{ notificationsCount }} {{ t('tooltip.new') }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item
              :key="`${index}-${notification.group_name}`"
              link
            >
              <!-- Avatar -->
              <v-list-item-avatar
                :class="[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.group_avatar}]"
                size="38"
                @click="$emit('openDetail', notification.reservation_id)"
              >
                <v-img
                  v-if="notification.group_avatar"
                  :src="notification.group_avatar"
                ></v-img>
                <span
                  v-else
                  class="text-lg"
                >
                  {{ getInitialName(notification.group_name) }}
                </span>
              </v-list-item-avatar>

              <!-- Content -->
              <v-list-item-content
                class="d-block"
                @click="$emit('openDetail', notification.reservation_id)"
              >
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification.group_name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-xs">
                  {{ notification.field_name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-xs">
                  {{ notification.time_str }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <!-- <span class="text--secondary text-xs mb-1">{{ notification.time_str }}</span> -->
                <v-btn
                  x-small
                  color="secondary"
                  @click.prevent="onUpdateReservation(notification.reservation_id)"
                >
                  {{ t('reservations.no_show') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <!-- <v-list-item
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              block
              color="primary"
            >
              Read All Notifications
            </v-btn>
          </v-list-item> -->
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiBellOutline, mdiBellBadgeOutline } from '@mdi/js'
import { getInitialName } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import { error } from '@core/utils/toasted'
import { updateReservation } from '@api'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
      require: true,
    },
    notificationsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const computedNotifications = computed(() => props.notification)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const onUpdateReservation = async id => {
      const resp = await updateReservation(id, {
        incomplete_reason: 1,
      })

      if (resp.ok) emit('refreshEvents')
      else error(resp.message.text)
    }

    return {
      // data
      computedNotifications,
      perfectScrollbarOptions,

      // methods
      getInitialName,
      onUpdateReservation,

      // i18n
      t,

      icons: {
        mdiBellOutline,
        mdiBellBadgeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
