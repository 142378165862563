<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
    >
      <v-autocomplete
        ref="autoContact"
        v-model="computedDataParams.id"
        :label="label"
        :items="participantsSearched"
        item-text="fullname"
        item-value="id"
        :loading="searchLoading"
        :search-input.sync="searchQuery"
        :filter="searchFilter"
        clearable
        hide-selected
        dense
        outlined
        chips
        hide-details
        :disabled="!computedDataParams.enabled"
        @change="onSelectContact"
        @keypress.enter="() => {
          addContact()
          $refs.autoContact.blur()
        }"
      >
        <template v-slot:no-data>
          <div>
            <v-list-item>
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none"
                  @click="() => {
                    addContact()
                    $refs.autoContact.blur()
                  }"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  {{ $t('reservations.add_new_participant') }}
                </a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="placeIndex > 0">
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none"
                  @click="() => {
                    addGuest()
                    $refs.autoContact.blur()
                  }"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                  {{ `${$t('reservations.guest')} "${searchQuery ? searchQuery : $t('reservations.player')} ${placeIndex + 1}"` }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </div>
        </template>

        <template v-slot:selection="{ attr, on, item }">
          <v-list-item-avatar
            v-if="item.id !== 0"
            class="my-2 mr-2"
            size="28"
          >
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="28"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium text-body-2"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content
            v-if="item.id !== 0"
            class="my-2 pa-0"
          >
            <v-list-item-title v-text="item.fullname"></v-list-item-title>
            <v-list-item-subtitle
              v-if="item.phone_number || item.email"
              v-text="item.phone_number ? item.phone_number : item.email"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-else
              v-text="'Guest Player'"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-avatar
            v-if="item.id !== 0"
            class="my-2 mr-2"
            size="28"
          >
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="28"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium text-body-2"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content
            v-if="item.id !== 0"
            class="my-2"
          >
            <v-list-item-title v-text="item.fullname"></v-list-item-title>
            <v-list-item-subtitle v-text="item.phone_number ? item.phone_number : item.email"></v-list-item-subtitle>
          </v-list-item-content>
          <div v-else>
            <v-list-item class="pa-0">
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none"
                  @click="() => {
                    addContact()
                    $refs.autoContact.blur()
                  }"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  {{ $t('reservations.add_new_participant') }}
                </a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="placeIndex > 0"
              class="pa-0"
            >
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none"
                  @click="() => {
                    addGuest()
                    $refs.autoContact.blur()
                  }"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                  {{ searchQuery ? `${$t('reservations.guest')} "${searchQuery}"` : `${$t('reservations.guest')} ${$t('reservations.player')} ${placeIndex + 1}` }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </div>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="computedDataParams.final_amount"
        :label="$t('transactions.price')"
        class="text-body-1"
        outlined
        prefix="$"
        dense
        hide-details
        :disabled="!computedDataParams.enabled || disabledPrice"
        @input="e => {
          if (!charUnique(e, '.') || regexValidator(computedDataParams.final_amount, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
            computedDataParams.final_amount = null
            reservationPrice = 0
            fullPrice = 0
          }
        }"
        @keypress="onlyNumberDot"
      />
    </v-col>

    <v-col
      cols="12"
      md="4"
      class="d-flex align-start"
      block
    >
      <v-btn
        v-if="!showOnsitePayment"
        color="primary"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 250px !important; min-width: 250px;'"
        :disabled="!computedDataParams.enabled"
        @click="showOnsitePayment = true"
      >
        inX: {{ placeIndex }} - Mark as Paid
        <!-- Mark as Paid -->
      </v-btn>

      <v-select
        v-else
        v-model="computedDataParams.onsite_payment_method_id"
        :label="$t('settings.onSite_payment_method')"
        :items="onsitePaymentMethodsOptions"
        item-text="name"
        item-value="id"
        class="text-body-1"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 250px !important; min-width: 250px;'"
        outlined
        dense
        hide-details
        @change="onSelectOnsitePaymentMethod"
      />

      <v-btn
        class="ml-2 pa-0"
        color="primary"
        :style="'max-width: 38px !important; min-width: 38px; max-width: 38px'"
        :disabled="!computedDataParams.enabled"
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </v-col>

    <v-navigation-drawer
      v-model="isContactModalOpen"
      :right="!$vuetify.rtl"
      persistent
      touchless
      app
      temporary
      width="100%"
      style="z-index: 5"
    >
      <div
        id="reservation-new-form"
        class="h-full"
      >
        <div class="drawer-header d-flex align-center mb-0">
          <span class="font-weight-semibold text-base text--primary">{{ $t('tooltip.new') }} {{ $t('contacts.contact') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isContactModalOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="formContactDialog"
          class="pa-5"
        >
          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row
              align="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.fullname"
                  :label="$t('users.fullname')"
                  dense
                  outlined
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.email"
                  :label="$t('users.email')"
                  dense
                  outlined
                  :rules="contactData.email ? [emailValidator] : []"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    v-model="phoneCode"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                        width: 35%;
                      "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneNumber"
                    :label="$t('users.phone')"
                    dense
                    outlined
                    :disabled="!phoneCode"
                    class="pl-1"
                    :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                    @keypress="onlyNumbers"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <v-btn
                  color="secondary"
                  class="me-3"
                  @click="onCreateContact"
                >
                  {{ $t('tooltip.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { getContactFacilitySearch, createContact } from '@api'
import {
  mdiDotsVertical, mdiPlusCircleOutline, mdiAccountOutline, mdiClose, mdiMenuDown,
} from '@mdi/js'
import { avatarText, title } from '@core/utils/filter'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
  emailValidator,
} from '@core/utils/validation'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { success, error } from '@core/utils/toasted'
import { trimSpace } from '@core/utils'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => ({
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: 0,
        paid: 'N',
        onsite_payment_method_id: null,
        empty: true,
        enabled: true,
        is_organizer: false,
      }),
      required: true,
    },
    selectedParticipant: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethodsOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    disabledPrice: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions } = usePhone()
    const computedDataParams = computed(() => props.dataParams)

    const participantsSearched = ref([])
    const contactSelectedId = ref(null)
    const searchQuery = ref(null)
    const searchLoading = ref(false)
    const timerSearch = ref(null)

    const showOnsitePayment = ref(false)

    const isContactModalOpen = ref(false)
    const contactSelected = ref(null)
    const contactData = ref({
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
    })
    const formContactDialog = ref(null)

    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(props.facility || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [{
            id: 0,
            first_name: searchQuery.value,
            last_name: searchQuery.value,
            fullname: searchQuery.value,
            phone_number: searchQuery.value,
            email: searchQuery.value,
          }]

          const filteredArr = response.data.filter(e => !props.selectedParticipant.includes(e.id))
          participantsSearched.value = newContact.concat(filteredArr)
        } else {
          participantsSearched.value = []
        }
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 700)
    }

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => { searchQuery.value = firSearch }, 50)
      }
      await getDataBySearch()
    })

    const addGuest = () => {
      computedDataParams.value.empty = false
      computedDataParams.value.id = 'guest'
      computedDataParams.value.fullname = `Player ${props.placeIndex + 1}`
      participantsSearched.value.push({
        id: computedDataParams.value.id,
        facility_id: null,
        first_name: null,
        last_name: null,
        fullname: computedDataParams.value.fullname,
        phone_code: null,
        phone_prefix: null,
        phone_number: null,
        email: null,
        status: null,
        user_fullname: null,
        user_app_id: null,
        avatar: null,
      })
      emit('updateNextEnabled', props.placeIndex + 1)
    }

    const addContact = () => {
      isContactModalOpen.value = true
      if (formContactDialog.value) formContactDialog.value.resetValidation()
      phoneCode.value = initCode.value
      if (searchQuery.value) contactData.value.fullname = searchQuery.value ?? ''
    }

    const onSelectContact = val => {
      if (val) {
        const finded = participantsSearched.value.find(e => e.id === val)
        computedDataParams.value.empty = false
        computedDataParams.value.id = finded.id
        computedDataParams.value.contact_id = finded.id
        computedDataParams.value.user_app_id = finded.user_app_id
        computedDataParams.value.fullname = finded.fullname
        computedDataParams.value.phone_number = finded.phone_number
        computedDataParams.value.email = finded.email
        emit('updateNextEnabled', props.placeIndex + 1)
      } else if (val === 0) {
        contactSelectedId.value = null

        participantsSearched.value = []

        // autoContact.value.reset()
        document.getElementById(`autoContact-${val}`).reset()
        addContact()
      } else {
        contactSelectedId.value = null
        participantsSearched.value = []
      }
    }

    const searchFilter = (item, queryText) => (
      (item.fullname && item.fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
        || (item.user_fullname && item.user_fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
        || (item.phone_number && item.phone_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
        || (item.email && item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    )

    const validatePhone = (e, code) => {
      if (code && e && /^([0-9])*$/.test(e)) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            contactData.value.phone_number = phoneNumberJS.number
            contactData.value.phone_code = phoneNumberJS.country
            contactData.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    const onAddContact = item => {
      computedDataParams.value.id = item.id
      computedDataParams.value.contact_id = item.id
      computedDataParams.value.user_app_id = null
      computedDataParams.value.fullname = item.fullname
      computedDataParams.value.phone_number = item.phone_number
      computedDataParams.value.email = item.email
      computedDataParams.value.final_amount = 0
      computedDataParams.value.paid = 'N'
      computedDataParams.value.onsite_payment_method_id = null
      computedDataParams.value.empty = false

      participantsSearched.value.push({
        id: item.id,
        facility_id: item.id,
        first_name: null,
        last_name: null,
        fullname: item.fullname,
        phone_code: item.phone_code,
        phone_prefix: item.phone_prefix,
        phone_number: item.phone_number,
        email: item.email,
        status: item.status,
        user_fullname: null,
        user_app_id: null,
        avatar: null,
      })
      emit('updateNextEnabled', props.placeIndex + 1)
      isContactModalOpen.value = false
      if (formContactDialog.value) formContactDialog.value.reset()
    }

    const onCreateContact = async () => {
      if (formContactDialog.value.validate()) {
        const bodyContact = {
          fullname: contactData.value.fullname ? title(trimSpace(contactData.value.fullname)) : null,
          phone_code: contactData.value.phone_code,
          phone_prefix: contactData.value.phone_prefix,
          phone_number: contactData.value.phone_number,
          email: contactData.value.email,
          status: 'A',
          categories_ids: contactData.value.categories_ids,
          facility_id: props.facility,
        }
        const resp = await createContact(bodyContact)
        if (resp.ok) {
          bodyContact.id = resp.data.contact_id
          onAddContact(bodyContact)
          success(resp.message)
        } else {
          error(resp.message.text)
        }
      }
    }

    const onSelectOnsitePaymentMethod = async onsitePaymentMethodId => {
      if (onsitePaymentMethodId === null || onsitePaymentMethodId === '*') {
        showOnsitePayment.value = false
        computedDataParams.value.onsite_payment_method_id = null
      } else {
        computedDataParams.value.onsite_payment_method_id = onsitePaymentMethodId
      }
    }

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
    })

    return {
      computedDataParams,

      participantsSearched,
      contactSelectedId,
      searchQuery,
      searchLoading,

      isContactModalOpen,
      contactSelected,
      contactData,
      formContactDialog,

      phoneNumber,
      phoneCode,
      initCode,
      phoneCodeOptions,
      validatePhone,

      searchFilter,
      onSelectContact,
      addContact,
      addGuest,
      avatarText,
      onCreateContact,

      showOnsitePayment,
      onSelectOnsitePaymentMethod,

      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      emailValidator,
      onlyNumberDot,
      onlyNumbers,

      icons: {
        mdiDotsVertical,
        mdiPlusCircleOutline,
        mdiAccountOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
  <style>
  .v-list-item {
    height: 100% !important;
  }
  </style>
