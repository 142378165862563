import { ref, watch, computed } from '@vue/composition-api'
import {
  getRecurringReservationfacility,
  getReservationByFacility,
  getReservationPaged,
  getFieldsByFacility,
  getCancellationReasonsActiveByGroup,
} from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import useFilters from '@/@core/utils/useFilters'
import fetchPaged from '@core/utils/fetchPaged'

export default function useReservationList() {
  const { t } = useUtils()
  const { updateFilterSubmodule, getFilterBySubmodule } = useFilters()
  const { hasActionPermission, hasPermission } = usePermissions()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()
  const { userData } = useCryptoJs()

  const cancellationReasons = ref([])
  const dataListTableRecurringReservations = ref([])
  const totalDataListTableRecurringReservations = ref(0)

  // { text: t('videos.weekdays').toUpperCase(), value: 'weekdays', show: true },
  const tableColumnsRecurringReservations = computed(() => [
    { text: 'ID', value: 'id', show: true },
    { text: t('fields.field').toUpperCase(), value: 'field_name', show: true },
    { text: t('players_group.type').toUpperCase(), value: 'reservation_type', show: true },
    { text: t('transactions.from_date').toUpperCase(), value: 'start_date', show: true },
    { text: t('transactions.to_date').toUpperCase(), value: 'end_date', show: true },
    { text: t('videos.time').toUpperCase(), value: 'start_time', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])

  const computedTableColumnsRecurringReservations = computed(() => tableColumnsRecurringReservations.value.filter(e => e.show))

  const dataListTableReservations = ref([])
  const totalDataListTableReservations = ref(0)
  const tableColumnsReservations = computed(() => [
    { text: 'ID', value: 'reservation_id', show: true },
    { text: t('fields.field').toUpperCase(), value: 'field_name', show: true },
    { text: t('transactions.date').toUpperCase(), value: 'date_str', show: true },
    { text: t('videos.time').toUpperCase(), value: 'time_str', show: true },
    { text: t('reservations.contact_name').toUpperCase(), value: 'contact_name', show: true },
    { text: t('payment_methods.payment').toUpperCase(), value: 'payment_status_str', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])
  const computedTableColumnsReservations = computed(() => tableColumnsReservations.value.filter(e => e.show))

  const weekdaysShort = computed(() => [
    { text: t('weekdays_short.mon'), value: 0 },
    { text: t('weekdays_short.tue'), value: 1 },
    { text: t('weekdays_short.wed'), value: 2 },
    { text: t('weekdays_short.thu'), value: 3 },
    { text: t('weekdays_short.fri'), value: 4 },
    { text: t('weekdays_short.sat'), value: 5 },
    { text: t('weekdays_short.sun'), value: 6 },
  ])

  const searchQueryRecurringReservations = ref('')
  const statusRecurringReservations = ref(null)
  const statusPaymentRecurringReservations = ref(null)
  const fieldRecurringReservations = ref(null)
  const modalFromRecurringReservations = ref(false)
  const modalToRecurringReservations = ref(false)
  const dateFromRecurringReservations = ref(null)
  const dateToRecurringReservations = ref(null)

  const loadingRecurringReservations = ref(false)
  const descSortRecurringReservations = ref([])
  const optionsRecurringReservations = ref({
    sortBy: ['start_date'],
    sortDesc: [true],
  })
  const searchQueryReservations = ref('')
  const statusReservations = ref(null)
  const statusPaymentReservations = ref(null)
  const fieldReservations = ref(null)
  const modalFromReservations = ref(false)
  const modalToReservations = ref(false)
  const dateFromReservations = ref(null)
  const dateToReservations = ref(null)

  const loadingReservations = ref(false)
  const descSortReservations = ref([])
  const optionsReservations = ref({
    sortBy: ['reservation_id'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const fieldsOptions = ref([])
  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  // fetch data
  const fetchFields = async facilityId => {
    let fields = null
    if (userData.value && userData.value.role !== 'A') {
      if (facilityId) {
        fields = await getFieldsByFacility(facilityId)
      }
    } else if (facilityId) fields = await getFieldsByFacility(facilityId)
    else fields = [] // await getFields()

    if (fields && fields.ok) fieldsOptions.value = fields.data
    else fieldsOptions.value = []
  }

  const fetchRecurringReservations = async () => {
    try {
      const response = await getRecurringReservationfacility(configFacility.value)

      const filterOption = {}
      filters.value = null
      if (searchQueryRecurringReservations.value) filterOption.search = searchQueryRecurringReservations.value
      filters.value = filterOption

      const filteredData = response.data.filter(
        reservation =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          reservation.end_date.toLowerCase().includes(searchQueryRecurringReservations.value) || reservation.start_date.toLowerCase().includes(searchQueryRecurringReservations.value),
      )

      dataListTableRecurringReservations.value = filteredData
      totalDataListTableRecurringReservations.value = filteredData.length
      loadingRecurringReservations.value = false
    } catch (e) {
      console.error(e)
      dataListTableRecurringReservations.value = []
      totalDataListTableRecurringReservations.value = 0
      loadingRecurringReservations.value = false
    }
  }

  const fetchReservations2 = async () => {
    try {
      const response = await getReservationByFacility(configFacility.value)

      const filterOption = {}
      filters.value = null
      if (searchQueryReservations.value) filterOption.search = searchQueryReservations.value
      filters.value = filterOption

      const filteredData = response.data.filter(
        reservation =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          reservation.field_name.toLowerCase().includes(searchQueryReservations.value) || reservation.time_str.toLowerCase().includes(searchQueryReservations.value),
      )

      dataListTableReservations.value = filteredData
      totalDataListTableReservations.value = filteredData.length
      loadingReservations.value = false
    } catch (e) {
      console.error(e)
      dataListTableReservations.value = []
      totalDataListTableReservations.value = 0
      loadingReservations.value = false
    }
  }

  const fetchReservations = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQueryReservations.value) filterOption.search = searchQueryReservations.value
    if (statusReservations.value) filterOption.status = statusReservations.value
    if (statusPaymentReservations.value) filterOption.payment_status = statusPaymentReservations.value
    if (fieldReservations.value) filterOption.field_id = fieldReservations.value
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (dateFromReservations.value) filterOption.start_date = dateFromReservations.value
    if (dateToReservations.value) filterOption.end_date = dateToReservations.value
    filters.value = filterOption
    const response = await fetchPaged(getReservationPaged, optionsReservations.value, filterOption)

    dataListTableReservations.value = response.data.reservations
    totalDataListTableReservations.value = response.data.total
    loadingReservations.value = false
  }

  const fetchCancellationReasons = async () => {
    const resp = await getCancellationReasonsActiveByGroup(configOrganization.value || 0)
    if (resp.ok) {
      cancellationReasons.value = resp.data.map(e => ({
        ...e,
        desc: 0,
        title: e.name,
        subtitle: null,
        value: e.id,
      }))
    } else {
      cancellationReasons.value = []
    }
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      optionsRecurringReservations.value = value
      optionsReservations.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) {
        searchQueryRecurringReservations.value = value.search
        searchQueryReservations.value = value.search
      }
    }
  }

  const getDataBySearchRecurringReservations = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchRecurringReservations()
    }, 1000)
  }

  const getDataBySearchReservations = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchReservations()
    }, 1000)
  }

  const resetFilters = () => {
    searchQueryRecurringReservations.value = ''
    fieldRecurringReservations.value = null
    dateFromRecurringReservations.value = null
    dateToRecurringReservations.value = null
    statusRecurringReservations.value = null
    statusPaymentRecurringReservations.value = null

    searchQueryReservations.value = ''
    fieldReservations.value = null
    dateFromReservations.value = null
    dateToReservations.value = null
    statusReservations.value = null
    statusPaymentReservations.value = null
  }

  watch([
    searchQueryRecurringReservations,
    fieldRecurringReservations,
    dateFromRecurringReservations,
    dateToRecurringReservations,
    statusRecurringReservations,
    statusPaymentRecurringReservations,
  ], async () => {
    loadingRecurringReservations.value = true
    flag.value = false
    if (!hasOptionsFilter.value) optionsRecurringReservations.value.page = 1
    if (searchQueryRecurringReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'recurringReservations', 'search', searchQueryRecurringReservations.value)
    await getDataBySearchRecurringReservations(500)
  })

  watch([optionsRecurringReservations], async () => {
    if (flag.value) {
      loadingRecurringReservations.value = true
      await fetchRecurringReservations()
    }
  })

  watch([searchQueryReservations, fieldReservations, dateFromReservations, dateToReservations, statusReservations, statusPaymentReservations], async () => {
    if (searchQueryReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'search', searchQueryReservations.value)
    if (fieldReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'field', fieldReservations.value)
    if (dateFromReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'dateFrom', dateFromReservations.value)
    if (dateToReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'dateTo', dateToReservations.value)
    if (statusReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'status', statusReservations.value)
    if (statusPaymentReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'statusPayment', statusPaymentReservations.value)

    loadingReservations.value = true
    flag.value = false
    if (!hasOptionsFilter.value) optionsReservations.value.page = 1
    await getDataBySearchReservations(500)
  })

  watch([optionsReservations], async () => {
    if (flag.value) {
      if (optionsReservations.value) updateFilterSubmodule('views-recurring-reservation-list', 'reservations', 'option', optionsReservations.value)
      loadingReservations.value = true
      await fetchReservations()
    }
  })

  watch([configFacility], async () => {
    resetFilters()
    if (configFacility.value) {
      fieldsOptions.value = []
      loadingRecurringReservations.value = true
      loadingReservations.value = true
      loadingRecurringReservations.value = true
      flag.value = false
      if (!hasOptionsFilter) {
        optionsRecurringReservations.value.page = 1
        optionsReservations.value.page = 1
      }
      await fetchFields(configFacility.value)
      await fetchReservations()
      await fetchRecurringReservations()
    }
  })

  // watch([fieldFilter], async () => {
  //   loading.value = true
  //   flag.value = false
  //   if (!hasOptionsFilter) options.value.page = 1
  //   await fetchPagedRecurringOrders()
  // })

  // watch([searchQuery], async () => {
  //   loading.value = true
  //   flag.value = false
  //   if (!hasOptionsFilter) options.value.page = 1
  //   await fetchPagedRecurringOrders()
  // })

  // watch([options], async () => {
  //   if (flag.value) {
  //     loading.value = true
  //     await fetchPagedRecurringOrders()
  //   }
  // })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'F' || status === 'A') return 'success'
    if (status === 'C') return 'error'

    return 'primary'
  }

  const resolveStatusPaymentVariant = status => {
    if (status === 'H') return 'hold'
    if (status === 'P') return 'paid'
    if (status === 'N') return 'pending'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  const resolveWeekdays = items => items.map(e => weekdaysShort.value.find(day => day.value === e).text).join(', ')

  return {
    cancellationReasons,
    dataListTableRecurringReservations,
    computedTableColumnsRecurringReservations,
    totalDataListTableRecurringReservations,
    searchQueryRecurringReservations,
    statusRecurringReservations,
    statusPaymentRecurringReservations,
    fieldRecurringReservations,
    modalFromRecurringReservations,
    modalToRecurringReservations,
    dateFromRecurringReservations,
    dateToRecurringReservations,
    loadingRecurringReservations,
    descSortRecurringReservations,
    optionsRecurringReservations,

    dataListTableReservations,
    computedTableColumnsReservations,
    totalDataListTableReservations,
    searchQueryReservations,
    statusReservations,
    statusPaymentReservations,
    fieldReservations,
    modalFromReservations,
    modalToReservations,
    dateFromReservations,
    dateToReservations,
    loadingReservations,
    descSortReservations,
    optionsReservations,

    filters,
    configOrganization,
    configFacility,
    weekdaysShort,
    maxDate,
    fieldsOptions,

    fetchFields,
    fetchReservations,
    fetchReservations2,
    fetchRecurringReservations,
    fetchCancellationReasons,
    resolveStatusVariant,
    resolveStatusPaymentVariant,
    resolveStatusText,
    resolveWeekdays,
    hasPermission,
    setFilters,
    setOptions,
    getFilterBySubmodule,
  }
}
