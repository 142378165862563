<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="pa-3"
      rounded="10"
      style="width: 100%"
    >
      <v-form ref="form">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ t('players_group.players_group') }}
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="py-0 px-8 mt-5 mb-7 justify-center d-flex flex-column">
          <v-row
            v-if="!isNew"
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="searchQuery"
                :placeholder="t('reservations.enter_name_phone_email')"
                outlined
                hide-details
                dense
                clearable
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <p
                id="addGroupForm"
                class="text-body-1 ma-0 btn-add"
              >
                + {{ t('reservations.add_new_group') }}
              </p>
            </v-col>

            <v-col
              cols="12"
              md="10"
            >
              <v-list>
                <div
                  v-for="(item, index) in searchData"
                  :key="item.id"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-avatar
                        color="primary"
                        :class="item.circle_logo ? '' : 'v-avatar-light-bg primary--text'"
                        size="32"
                        tile
                      >
                        <v-img
                          v-if="item.circle_logo"
                          :src="item.circle_logo"
                        ></v-img>
                        <span
                          v-else
                          class="font-weight-medium"
                        >{{ avatarText(item.circle_name) }}</span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.circle_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.user_fullname">
                        <span>{{ item.user_fullname }}</span>
                        <span class="ml-2">{{ item.phone_number }}</span>
                        <span
                          class="ml-3"
                          :class="item.is_organizer === 'Y' ? 'success--text' : 'error--text'"
                        >
                          {{ `${item.is_organizer === 'Y' ? 'Organizer' : 'Member'}` }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :value="idAdded(item.id)"
                        color="secondary"
                        @change="onChange($event, item)"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index !== (searchData.length - 1)"
                  />
                </div>
              </v-list>
            </v-col>
          </v-row>

          <v-row
            v-else
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="postData.name"
                :label="t('sports.name')"
                dense
                outlined
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <!-- <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="postData.facilities_ids"
                :label="t('facilities.facility')"
                :items="facilities"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                multiple
                persistent-hint
                dense
                outlined
                :rules="[required]"
              >
                <template #selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    label
                    dense
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-select>
            </v-col> -->

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="postData.visibility"
                :label="t('players_group.visibility')"
                :items="visibilityOptions"
                item-text="text"
                item-value="value"
                dense
                outlined
                :rules="[required]"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="8"
            >
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  v-model="phoneCode"
                  dense
                  outlined
                  :items="phoneCodeOptions"
                  item-text="text"
                  item-value="value"
                  style="
                    width: 35%;
                  "
                  hide-details
                  :rules="[required]"
                  @change="() => phoneNumber = null"
                >
                  <template v-slot:item="{ item }">
                    <img
                      :src="item.image"
                      :alt="item.value"
                      width="20"
                      height="auto"
                      class="mr-2"
                    >
                    {{ item.text }}
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="phoneNumber"
                  :label="t('users.phone')"
                  dense
                  outlined
                  :disabled="!phoneCode"
                  class="pl-1"
                  :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                ></v-text-field>

                <v-text-field
                  v-model="organizer.fullname"
                  :label="t('users.name')"
                  dense
                  outlined
                  class="pl-2"
                  :disabled="!phoneCode"
                  :readonly="organizer.is_user === 'Y'"
                  :rules="[required]"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip
                      bottom
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-icon
                          size="20"
                          :color="organizer.is_user === 'Y' ? 'success' : 'info'"
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                        >
                          {{ organizer.is_user === 'Y' ? icons.mdiAccountCheck : icons.mdiAccount }}
                        </v-icon>
                      </template>
                      <span>{{ organizer.is_user === 'Y' ? 'Is User' : 'Guess' }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-textarea
                v-model="postData.description"
                :label="t('sports.description')"
                dense
                outlined
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center py-3">
          <div class="d-flex justify-space-between">
            <v-btn
              v-if="isNew"
              class="text-xs mr-3"
              color="secondary"
              dark
              width="30%"
              rounded
              outlined
              @click="onCreate"
            >
              {{ t('tooltip.save') }}
            </v-btn>

            <v-btn
              class="text-xs"
              color="lightGray"
              dark
              width="30%"
              rounded
              outlined
              @click="computedIsOpen = false"
            >
              {{ t('tooltip.close') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-newline */
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted,
} from '@vue/composition-api'
import {
  getPlayersGroupsByFacilitySearch,
  createPlayersGroup,
  getPlayersGroupsSearch,
} from '@api'
import { mdiAccountCheck, mdiAccount } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { success, error } from '@core/utils/toasted'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    facilities: {
      type: Array,
      default: () => [],
      required: true,
    },
    playersGroupData: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions } = usePhone()

    const form = ref(null)
    const postData = ref({
      avatar: null,
      name: null,
      facilities_ids: [props.facility],
      status: 'A',
      visibility: null,
      description: null,
      organizers: [],
    })
    const isNew = ref(false)
    const searchQuery = ref('')
    const timerSearch = ref(null)
    const searchData = ref([])
    const itemsSelected = ref([])
    const visibilityOptions = computed(() => [
      { text: t('players_group.private'), value: 'P' },
      { text: t('players_group.public'), value: 'B' },
    ])
    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')
    const organizer = ref({
      username: null,
      fullname: null,
      phone_number: null,
      phone_code: initCode.value,
      phone_prefix: null,
      is_user: null,
    })

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })
    const computedData = computed({
      set: val => emit('update:players-group-data', val),
      get: () => props.playersGroupData,
    })
    const computedFacility = computed(() => props.facility)

    const fetchUserByPhoneNumber = async phone => {
      const respSearch = await getPlayersGroupsSearch(phone)
      const search = respSearch.data
      if (search) {
        organizer.value.username = search.username
        organizer.value.fullname = search.fullname
        organizer.value.phone_prefix = search.phone_prefix
        organizer.value.is_user = search.is_user
      } else organizer.value.is_user = 'N'
    }

    const validatePhone = (e, code) => {
      if (code && e) {
        let phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            organizer.value.phone_number = phoneNumberJS.number
            organizer.value.phone_code = phoneNumberJS.country
            organizer.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            if (!organizer.value.is_user) fetchUserByPhoneNumber(organizer.value.phone_number)
            phoneNumberJS = undefined

            return true
          }
        }
      }

      organizer.value.fullname = null
      organizer.value.is_user = null
      organizer.value.username = null
      organizer.value.phone_code = null

      if (e === '' || e === null) return true

      return 'Invalid number'
    }

    const fetchPlayersGroups = async () => {
      if (searchQuery.value) {
        const response = await getPlayersGroupsByFacilitySearch(computedFacility.value || 0, searchQuery.value)
        if (response.ok) {
          searchData.value = []
          searchData.value = response.data
        } else {
          searchData.value = []
        }
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchPlayersGroups()
      }, 1000)
    }

    const idAdded = id => {
      const finded = itemsSelected.value.find(item => item.id === id)

      return !!finded
    }

    const onAdd = item => {
      if (!itemsSelected.value.find(e => item.id === e.id)) {
        itemsSelected.value.push(item)
        computedData.value = itemsSelected.value
      }
    }

    const onRemove = id => {
      itemsSelected.value = itemsSelected.value.filter(item => item.id !== id)
      computedData.value = itemsSelected.value
    }

    const onChange = (event, item) => {
      if (event) onAdd(item)
      else onRemove(item.id)
    }

    const onCreate = async () => {
      if (form.value.validate()) {
        const body = {
          name: postData.value.name,
          facilities_ids: postData.value.facilities_ids,
          status: postData.value.status,
          visibility: postData.value.visibility,
          description: postData.value.description,
          organizers: [organizer.value],
        }
        const resp = await createPlayersGroup(body)
        if (resp.ok) {
          body.id = resp.data.id
          onAdd({
            id: body.id,
            circle_name: postData.value.name,
            circle_logo: postData.value.avatar,
            user_fullname: null,
            phone_number: null,
            email: null,
            is_organizer: 'N',
          })
          success(resp.message)
        } else {
          error(resp.message.text)
        }
      }
    }

    watch([searchQuery], async () => {
      await getDataBySearch()
    })

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        // const responseCategories = await getContactCategoryActiveFacility(computedFacility.value || 0)
        // categories.value = responseCategories.data

        if (!computedData.value.length) itemsSelected.value = []

        setTimeout(() => {
          const btn = document.getElementById('addGroupForm')
          btn.addEventListener('click', () => {
            isNew.value = true
            computedData.value = []
          })
        }, 200)
      } else {
        setTimeout(() => {
          isNew.value = false
          searchQuery.value = ''
          searchData.value = []
        }, 200)
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
    })

    return {
      // data
      form,
      postData,
      isNew,
      searchQuery,
      searchData,
      itemsSelected,
      visibilityOptions,

      initCode,
      phoneCodeOptions,
      phoneCode,
      phoneNumber,
      organizer,

      // computeds
      computedIsOpen,
      computedData,

      // methods
      idAdded,
      onAdd,
      onRemove,
      onChange,
      onCreate,
      avatarText,
      validatePhone,
      required,
      t,

      icons: {
        mdiAccountCheck,
        mdiAccount,
      },
    }
  },
}
</script>
