<template>
  <div
    class="text-xs h-full border-event text-body-1 d-flex flex-column"
    :class="{'pa-2 pl-3': smallWidth, 'px-3 py-0': !smallWidth, 'cursor-default': ['blocked_time', 'blocked'].includes(event.extended_props.card_type) }"
  >
    <div
      class="border-event-in"
      :style="event.extended_props.card_type === 'blocked' ? '' : `border-left: 5px solid ${event.border};`"
    ></div>
    <div
      class="font-weight-bold my-auto"
      :class="{
        'text-body-2': (smallWidth || event.extended_props.card_type === 'blocked'),
        'text-body-1': !(smallWidth || event.extended_props.card_type === 'blocked'),
        'd-flex justify-space-between align-center': (event.recurring_id || event.include_video === 'Y'),
      }"
      style="text-wrap: pretty; line-height: 1.8rem;"
    >
      <v-icon
        v-if="['blocked_time', 'blocked'].includes(event.extended_props.card_type)"
        color="white"
      >
        {{ icons.mdiCancel }}
      </v-icon>
      {{ event.extended_props.card_type === 'reservation' ? event.contact_name :
        event.extended_props.card_type === 'blocked_time' ? t('fields.blocked_time') : t('fields.blocked_by_shared_field')
      }}

      <div class="">
        <v-fab-transition v-if="event.recurring_id">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                size="25"
                color="white"
                v-on="on"
              >
                {{ icons.mdiSync }}
              </v-icon>
            </template>
            <span>{{ `${t('reservations.recurring')} #${event.recurring_id}` }}</span>
          </v-tooltip>
        </v-fab-transition>

        <v-icon
          v-if="event.include_video === 'Y'"
          size="25"
          color="white"
          class="ml-1"
        >
          {{ icons.mdiVideoOutline }}
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mdiVideoOutline, mdiSync, mdiCancel } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  name: 'EventSmall',
  props: {
    event: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    const smallWidth = computed(() => document.documentElement.clientWidth < 768)

    return {
      smallWidth,

      t,

      icons: {
        mdiVideoOutline,
        mdiSync,
        mdiCancel,
      },
    }
  },
}
</script>

<style lang="scss">
.tiny-spacing {
  letter-spacing: 0.1px !important;
}
</style>
